import { Node } from '@tiptap/core';

const PlaceholderExtension = Node.create({
  name: 'placeholderExtension',

  addOptions() {
    return {
      store: null,
      relatedOrg: 'Related Organisation',
    };
  },

  addCommands() {
    return {
      replacePlaceholders:
        (text) =>
        ({ chain }) => {
          const currentOrgName = this.options.store?.state?.currentOrg?.name || 'Unknown Organisation';
          const currentUser = this.options.store?.state?.user || { firstname: 'Unknown', surname: 'User' };
          const relatedOrg = this.options.relatedOrg || 'Related Organisation';

          const replacedText = text
            .replace(/{CurrentOrg}/g, currentOrgName)
            .replace(/{CurrentUser}/g, `${currentUser.firstname} ${currentUser.surname}`)
            .replace(/{relatedOrg}/g, relatedOrg);

          return chain().insertContent(replacedText).run();
        },
    };
  },
});

export default PlaceholderExtension;
